import styled from "styled-components";
import { ThemeProviderProps } from "@src/theme";

const Pre = styled.pre<ThemeProviderProps>`
  padding: 1.2em 0.5em;
  width: 50rem;
  border-radius: 0.3rem;
  margin: 3em auto;
  font-family: ${(props) => props.theme.fonts.Monospace};
  font-variant-ligatures: none;
  max-width: 98%;
  overflow: auto;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    font-size: 0.8125rem;
    line-height: 1.375rem;
  }
`;

const Line = styled.div`
  margin-left: 0.5em;
`;

const LineNumber = styled.div<ThemeProviderProps>`
  display: inline-block;
  width: 3em;
  padding-right: 1em;
  text-align: right;
  color: ${(props) => props.theme.code.color.Selection};
  user-select: none;

  &::before {
    content: attr(data-line-number);
  }
`;

const HighlightedLineNumber = styled(LineNumber)<ThemeProviderProps>`
  color: ${(props) => props.theme.code.color.Comment};
`;

const HighlightedLine = styled(Line)<ThemeProviderProps>`
  background-color: ${(props) => props.theme.code.color.CurrentLine};
  border-left: 1em solid ${(props: ThemeProviderProps) => props.theme.code.color.Selection};
  margin-left: -0.5em;
  padding-left: 0;
  padding-right: 0.5em;
  margin-right: -0.5em;
`;

const Meta = styled.div`
  margin: 0 2em;
  display: flex;
  justify-content: space-between;
`;

const Language = styled.div<ThemeProviderProps>`
  position: relative;
  text-align: left;
  color: ${(props) => props.theme.code.color.Selection};
  font-family: ${(props) => props.theme.fonts.Monospace};
  text-transform: uppercase;
  font-weight: bold;
`;

const Filename = styled.div<ThemeProviderProps>`
  text-align: right;
  color: ${(props) => props.theme.code.color.Selection};
  font-family: ${(props) => props.theme.fonts.Monospace};
  font-weight: bold;
`;

export { Pre, Line, LineNumber, HighlightedLine, HighlightedLineNumber, Meta, Language, Filename };
