import { GatsbyBrowser } from "gatsby";
import * as React from "react";
import { HelmetProvider } from "react-helmet-async";
import { StyleSheetManager } from "styled-components";
import { App } from "./src/App";

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => (
  <HelmetProvider>
    <StyleSheetManager>
      <App>{element}</App>
    </StyleSheetManager>
  </HelmetProvider>
);
