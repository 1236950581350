import styled from "styled-components";
import { ThemeProviderProps } from "@src/theme";

const Blockquote = styled.blockquote<ThemeProviderProps>`
  margin: 0 auto 1.4rem auto;
  width: ${(props) => props.theme.dimensions.contentWidth};
  max-width: 90%;
  border-left: 5px solid ${(props) => props.theme.site.color.SubtleBackground};
  padding: 0.6rem 0 0.6rem 1.4rem;

  p {
    margin-inline: 0;
    width: auto;
    max-width: initial;
  }
`;

export default Blockquote;
