import { CodeThemeConfig, SiteThemeConfig } from ".";

// Tomorrow Night
const DarkThemeCodeConfig: CodeThemeConfig = {
  color: {
    Background: "#1d1f21",
    CurrentLine: "#282a2e",
    Selection: "#373b41",
    Foreground: "#c5c8c6",
    Comment: "#969896",
    Red: "#cc6666",
    Orange: "#de935f",
    Yellow: "#f0c674",
    Green: "#b5bd68",
    Aqua: "#8abeb7",
    Blue: "#81a2be",
    Purple: "#b294bb",
  },
};

const DarkThemeSiteConfig: SiteThemeConfig = {
  color: {
    Background: "#262626",
    SubtleBackground: "#373b41",
    Footer: {
      Background: "black",
      Foreground: "white",
    },
    Foreground: "#f6f6f6",
    Title: {
      Foreground: "#ffffff",
    },
    SubtleForeground: "#dadada",
    Tag: {
      Foreground: "#f6f6f6",
      Background: "#1c1c1c",
      Border: "#d6d6d6",
      Hover: {
        Foreground: "#8d8d8d",
        Background: "#f4f4f4",
      },
    },
    SocialMediaIcons: {
      Hover: {
        Foreground: "#f6f6f6",
      },
    },
    MobileMenuIcons: {
      Foreground: "#e9e9e9",
    },
  },
};

export { DarkThemeCodeConfig, DarkThemeSiteConfig };
