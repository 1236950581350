import { omit } from "lodash";
import type { MDXComponents } from "mdx/types";
import styled from "styled-components";
import { ThemeProviderProps } from "@src/theme";
import Blockquote from "./blockquote";
import { Code } from "./code/mdx";
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from "./headings";
import HR from "./hr";
import Link from "./link";
import Paragraph from "./paragraph";
import { Pre } from "./pre/mdx";

// Specfic element stylings for mdx pages

const PostHeading1 = styled(Heading1)<ThemeProviderProps>`
  width: ${(props) => props.theme.dimensions.contentWidth};
  max-width: 90%;
`;
const PostHeading2 = styled(Heading2)<ThemeProviderProps>`
  width: ${(props) => props.theme.dimensions.contentWidth};
  max-width: 90%;
`;
const PostHeading3 = styled(Heading3)<ThemeProviderProps>`
  width: ${(props) => props.theme.dimensions.contentWidth};
  max-width: 90%;
`;
const PostHeading4 = styled(Heading4)<ThemeProviderProps>`
  width: ${(props) => props.theme.dimensions.contentWidth};
  max-width: 90%;
`;
const PostHeading5 = styled(Heading5)<ThemeProviderProps>`
  width: ${(props) => props.theme.dimensions.contentWidth};
  max-width: 90%;
`;
const PostHeading6 = styled(Heading6)<ThemeProviderProps>`
  width: ${(props) => props.theme.dimensions.contentWidth};
  max-width: 90%;

  text-transform: uppercase;
  font-size: 1rem;
`;

const PostParagraph = styled(Paragraph)<ThemeProviderProps>``;

const UnorderedList = styled.ul`
  color: ${(props) => props.theme.site.color.SubtleForeground};
  width: ${(props) => props.theme.dimensions.contentWidth};
  max-width: 90%;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6rem;
  font-feature-settings: "kern", "liga", "clig", "calt";
  margin: 0.4rem auto 0.4rem auto;
  font-family: ${(props) => props.theme.fonts.Body};

  @media ${(props) => props.theme.mediaQueries.mobile} {
    margin-left: 2.2rem;
    font-size: 0.8125rem;
  }

  & li p {
    max-width: 100%;
  }
`;

const OrderedList = styled.ol`
  color: ${(props) => props.theme.site.color.SubtleForeground};
  width: ${(props) => props.theme.dimensions.contentWidth};
  max-width: 90%;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6rem;
  font-feature-settings: "kern", "liga", "clig", "calt";
  margin: 0.4rem auto 0.4rem auto;
  font-family: ${(props) => props.theme.fonts.Body};

  @media ${(props) => props.theme.mediaQueries.mobile} {
    margin-left: 2.2rem;
    font-size: 0.8125rem;
  }

  & li p {
    max-width: 100%;
  }

  ol li {
    list-style-type: lower-alpha;
  }

  ol ol li {
    list-style-type: lower-roman;
  }
`;

const MdxElements: MDXComponents = {
  h1: PostHeading1,
  h2: PostHeading2,
  h3: PostHeading3,
  h4: PostHeading4,
  h5: PostHeading5,
  h6: PostHeading6,
  p: PostParagraph,
  ul: UnorderedList,
  ol: OrderedList,
  thematicBreak: HR,
  hr: (props) => <HR {...props} />,
  blockquote: Blockquote,
  // li
  // em
  // strong
  // table
  // tr
  // td
  // th
  // img
  a: (props) => <Link {...omit(props, ["color", "href"])} to={props.href!} />,
  pre: Pre,
  code: Code,
};

export default MdxElements;
