import { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import BurgerMenuSVG from "@src/assets/svgs/Burger-Menu.svg";
import CrossMenuSVG from "@src/assets/svgs/Cross-Menu.svg";
import { AllPostsLink, KablamoWebsiteLink, GetInTouchLink } from "@src/components/siteLinks";
import { ThemeProviderProps } from "@src/theme";

const MobileMenuIcon = styled.span<ThemeProviderProps>`
  fill: ${(props) => props.theme.site.color.MobileMenuIcons.Foreground};
`;

const MobileMenuLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-left: 1.2rem;
  > * {
    margin-top: 2rem;
  }
`;

const MobileMenuOverlay = styled.div`
  position: fixed;
  top: var(--nav-header-height);
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100 * var(--lvh) - var(--nav-header-height));
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
`;

const MobileMenuGlobalStyle = createGlobalStyle<ThemeProviderProps>`
  @media ${(props) => props.theme.mediaQueries.mobile} {
    html, body {
      overflow: hidden;
    }
  }
`;

const MobileMenuWrapper = styled.div<ThemeProviderProps>`
  position: fixed;
  top: var(--nav-header-height);
  left: 0;
  z-index: 3;
  width: 70vw;
  height: calc(100 * var(--lvh) - var(--nav-header-height));
  background-color: ${(props) => props.theme.site.color.Background};
  color: white;
`;

const MobileMenu = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      window.scrollTo(0, 0);
    }
  }, [show]);

  const close = () => setShow(false);
  const open = () => setShow(true);

  return (
    <div>
      {show ? (
        <MobileMenuIcon onClick={close}>
          <CrossMenuSVG />
        </MobileMenuIcon>
      ) : (
        <MobileMenuIcon onClick={open}>
          <BurgerMenuSVG />
        </MobileMenuIcon>
      )}
      {show && (
        <>
          <MobileMenuGlobalStyle />
          <MobileMenuOverlay onClick={close} />
          <MobileMenuWrapper>
            <MobileMenuLinksContainer>
              <div>
                <AllPostsLink onClick={close} />
              </div>
              <div>
                <KablamoWebsiteLink onClick={close} />
              </div>
              <div>
                <GetInTouchLink onClick={close} />
              </div>
            </MobileMenuLinksContainer>
          </MobileMenuWrapper>
        </>
      )}
    </div>
  );
};

export default MobileMenu;
