import * as React from "react";
import styled, { withTheme } from "styled-components";
import Svg from "@src/assets/svgs/kablamo-logo-horizontal.svg";
import { ThemeConfig, ThemeScheme } from "@src/theme";

const ColoredSvg = styled(Svg)<{ color: string }>`
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  color: ${(props) => props.color};
`;

const KablamoLogoHorizontal = withTheme(
  ({ theme, ...props }: React.SVGAttributes<SVGElement> & { theme: ThemeConfig }) => (
    <ColoredSvg {...props} color={theme.scheme === ThemeScheme.DARK ? "#fffffe" : "#020202"} />
  ),
);

export default KablamoLogoHorizontal;
