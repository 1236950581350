import { CodeThemeConfig, SiteThemeConfig } from ".";

// Tomorrow
const CurrentLine = "#efefef";
const Selection = "#d6d6d6";
const Foreground = "#4d4d4c";
const Comment = "#8e908c";
const Red = "#c82829";
const Orange = "#f5871f";
const Yellow = "#eab700";
const Green = "#718c00";
const Aqua = "#3e999f";
const Blue = "#4271ae";
const Purple = "#8959a8";

const LightThemeCodeConfig: CodeThemeConfig = {
  color: {
    Background: "#f9f9f9",
    CurrentLine,
    Selection,
    Foreground,
    Comment,
    Red,
    Orange,
    Yellow,
    Green,
    Aqua,
    Blue,
    Purple,
  },
};

const LightThemeSiteConfig: SiteThemeConfig = {
  color: {
    Background: "#ffffff",
    SubtleBackground: Selection,
    Footer: {
      Background: "#f4f4f4",
      Foreground: "#2a2a2a",
    },
    Foreground: "#1c1c1c",
    Title: {
      Foreground: "#1c1c1c",
    },
    SubtleForeground: "#2f2f2f",
    Tag: {
      Foreground: "#8d8d8d",
      Border: "#d6d6d6",
      Background: "#f4f4f4",
      Hover: {
        Foreground: "#f6f6f6",
        Background: "#1c1c1c",
      },
    },
    SocialMediaIcons: {
      Hover: {
        Foreground: "#1d1f21",
      },
    },
    MobileMenuIcons: {
      Foreground: "#8d8d8d",
    },
  },
};

export { LightThemeCodeConfig, LightThemeSiteConfig };
