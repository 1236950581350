import { Link as GatsbyLink, type GatsbyLinkProps } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import styled from "styled-components";
import { ThemeProviderProps } from "@src/theme";

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}: React.PropsWithoutRef<GatsbyLinkProps<unknown>>) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <OutboundLink> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <OutboundLink {...other} href={to} target="_blank" rel="noopener noreferrer">
      {children}
    </OutboundLink>
  );
};

const Anchor = styled(Link)<ThemeProviderProps>`
  color: ${(props) => props.theme.site.color.Foreground};
  font-size: 1em;
  font-feature-settings: "kern", "liga", "clig", "calt";
`;

const SubtleLink = styled(Anchor)<ThemeProviderProps>`
  color: ${(props) => props.theme.code.color.Comment};
  font-family: ${(props) => props.theme.fonts.Monospace};
  margin: 0 0.4rem;

  &:hover {
    color: ${(props) => props.theme.site.color.Foreground};
  }
`;

const NotSoSubtleLink = styled(Anchor)<ThemeProviderProps>`
  color: ${(props) => props.theme.code.color.Comment};
  font-family: ${(props) => props.theme.fonts.HeaderSans};
  margin: 0 0.4rem;

  &:hover {
    color: ${(props) => props.theme.site.color.Foreground};
  }
`;

export default Anchor;

export { Anchor, SubtleLink, NotSoSubtleLink };
