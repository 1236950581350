import styled from "styled-components";
import { Pre } from "../pre/styled";

// eslint-disable-next-line import/prefer-default-export
export const Code = styled.code`
  font-variant-ligatures: none;
  --code-color: ${(props) => props.theme.site.color.SubtleForeground};
  --code-bg-color: ${(props) => props.theme.site.color.Background};
  --code-border-color: ${(props) => props.theme.site.color.SubtleBackground};

  *:not(${Pre}) > & {
    color: var(--code-color);
    background-color: var(--code-bg-color);
    border: 1px solid var(--code-border-color);
    font-family: ${(props) => props.theme.fonts.Monospace};
    padding: 0.2em 0.4em;
    font-size: 0.8em;
    border-radius: 3px;
  }
`;
