import styled from "styled-components";
import KablamoBoltSVG from "@src/assets/svgs/Kablamo-bolt.svg";
import { ThemeScheme, ThemeProviderProps } from "@src/theme";

interface KablamoBoltProps {
  color?: string;
}

interface ThemedKablamoBoltProps {
  small?: boolean;
  subtle?: boolean;
}

const LogoWrapper = styled.div<ThemeProviderProps & ThemedKablamoBoltProps>`
  width: ${({ small }) => (small ? "12px" : "60px")};
  height: ${({ small }) => (small ? "12px" : "60px")};
  fill: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.subtle
      ? props.theme.site.color.SubtleBackground
      : props.theme.scheme === ThemeScheme.LIGHT
      ? "#020202"
      : "#FFFFFF"};
`;

export const KablamoBolt = ({ color }: KablamoBoltProps) => (
  <div style={{ fill: `${color}` }}>
    <KablamoBoltSVG />
  </div>
);

export const ThemedKablamoBolt = ({ small, subtle }: ThemedKablamoBoltProps) => (
  <LogoWrapper small={small} subtle={subtle}>
    <KablamoBoltSVG />
  </LogoWrapper>
);
