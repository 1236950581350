import styled from "styled-components";
import { ThemeProviderProps } from "../theme";
import Link from "./elements/link";

const MenuLink = styled(Link)<ThemeProviderProps>`
  color: ${(props) => props.theme.site.color.Foreground};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.Title};
  line-height: 1rem;
  letter-spacing: 0.05em;

  &:hover {
    border-bottom: 2px ${(props) => props.theme.site.color.Title.Foreground} solid;
  }
`;

export default MenuLink;
