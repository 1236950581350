import React from "react";
import NavMenuLink from "@src/components/navMenuLink";

interface SiteLinkProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const AllPostsLink = (props: SiteLinkProps) => (
  <NavMenuLink {...props} to="/">
    All Posts
  </NavMenuLink>
);

export const KablamoWebsiteLink = (props: SiteLinkProps) => (
  <NavMenuLink {...props} as="a" href="https://www.kablamo.com.au">
    Kablamo Website
  </NavMenuLink>
);

export const GetInTouchLink = (props: SiteLinkProps) => (
  <NavMenuLink {...props} as="a" href="https://www.kablamo.com.au/get-in-touch">
    Get in touch
  </NavMenuLink>
);
