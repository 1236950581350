import React from "react";
import Favicon from "react-favicon";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import Footer from "@src/components/footer";
import NavHeader from "@src/components/navHeader";
import useDarkMode from "@src/hooks/useDarkMode";
import { DarkThemeCodeConfig, DarkThemeSiteConfig } from "@src/theme/dark";
import { LightThemeCodeConfig, LightThemeSiteConfig } from "@src/theme/light";
import * as Fonts from "./fonts";

type CodeThemeColorConfig = {
  Background: string;
  CurrentLine: string;
  Selection: string;
  Foreground: string;
  Comment: string;
  Red: string;
  Orange: string;
  Yellow: string;
  Green: string;
  Aqua: string;
  Blue: string;
  Purple: string;
};

type ThemeFontConfig = {
  System: string;
  HeaderSans: string;
  Title: string;
  Body: string;
  Monospace: string;
};

enum ThemeScheme {
  LIGHT = "light",
  DARK = "dark",
}

type ThemeDimensions = {
  maxWidth: string;
  mainWidth: string;
  contentWidth: string;
};

type SiteThemeColorConfig = {
  Footer: {
    Foreground: string;
    Background: string;
  };
  Foreground: string;
  Background: string;
  SubtleBackground: string;
  SubtleForeground: string;
  Title: {
    Foreground: string;
  };
  Tag: {
    Foreground: string;
    Border: string;
    Background: string;
    Hover: {
      Foreground: string;
      Background: string;
    };
  };
  SocialMediaIcons: {
    Hover: {
      Foreground: string;
    };
  };
  MobileMenuIcons: {
    Foreground: string;
  };
};

type MediaQueriesConfig = {
  mobile: string;
  desktop: string;
};

type SiteThemeConfig = {
  color: SiteThemeColorConfig;
};

type CodeThemeConfig = {
  color: CodeThemeColorConfig;
};

type ThemeConfig = {
  color: CodeThemeColorConfig;
  fonts: ThemeFontConfig;
  scheme: ThemeScheme;
  dimensions: ThemeDimensions;
  site: SiteThemeConfig;
  siteInverted: SiteThemeConfig;
  code: CodeThemeConfig;
  mediaQueries: MediaQueriesConfig;
};

type ThemeProviderProps = {
  theme: ThemeConfig;
  color?: keyof CodeThemeColorConfig;
};

const GlobalStyle = createGlobalStyle<ThemeProviderProps>`
  html {
    font-size: 16px;
  }

 body {
    color: ${(props) => props.theme.site.color.Foreground};
    background-color: ${(props) => props.theme.site.color.Background}
 }

 :root {
  color-scheme: only ${(props) => props.theme.scheme};

  --svh: 1vh;
  @supports (height: 1svh) {
    --svh: 1svh;
  }

  --dvh: 1vh;
  @supports (height: 1dvh) {
    --dvh: 1dvh;
  }

  --lvh: 1vh;
  @supports (height: 1lvh) {
    --lvh: 1lvh;
  }
 }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  min-height: 100vh;
`;

const toggleTheme = (
  isDarkMode: boolean,
): Omit<ThemeConfig, "color"> & { color?: string; isDarkMode: boolean } => {
  return {
    isDarkMode,
    fonts: Fonts,
    scheme: isDarkMode ? ThemeScheme.DARK : ThemeScheme.LIGHT,
    dimensions: {
      maxWidth: "80rem",
      mainWidth: "60rem",
      contentWidth: "45rem",
    },
    site: isDarkMode ? DarkThemeSiteConfig : LightThemeSiteConfig,
    siteInverted: isDarkMode ? LightThemeSiteConfig : DarkThemeSiteConfig,
    code: isDarkMode ? DarkThemeCodeConfig : LightThemeCodeConfig,
    mediaQueries: {
      mobile: "(max-width: 900px)",
      desktop: "(min-width: 900px)",
    },
  };
};

const Theme = ({
  children,
  showDarkModeToggle = true,
}: {
  children: React.ReactNode;
  showDarkModeToggle?: boolean;
}) => {
  const [isDarkMode, setDarkMode] = useDarkMode();
  const theme = React.useMemo(() => toggleTheme(isDarkMode), [isDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Favicon url={isDarkMode ? "/favicon-light.ico" : "/favicon-dark.ico"} />
        <NavHeader
          theme={theme}
          darkMode={{
            showToggle: showDarkModeToggle,
            isDarkMode,
            toggleHandler: () => setDarkMode((previous) => !previous),
          }}
        />
        <GlobalStyle />
        {children}
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

// A util for use in styled-components that allows overridding of
// colors, and inverions
const themeColorPicker =
  (defaultColor: keyof CodeThemeColorConfig) =>
  (props: ThemeProviderProps): string => {
    const theme = props.theme.code.color;
    return theme[props.color || defaultColor];
  };

export {
  Theme,
  ThemeConfig,
  ThemeProviderProps,
  themeColorPicker,
  ThemeScheme,
  SiteThemeConfig,
  CodeThemeConfig,
};
