import { useLocalStorage, useMediaQuery } from "@kablamo/kerosene-ui";
import * as React from "react";

const isNullableBoolean = (value: unknown): value is boolean | null =>
  value === null || typeof value === "boolean";

export default function useDarkMode() {
  // Use our useLocalStorage hook to persist state through a page refresh.
  const [value, setValue] = useLocalStorage("dark-mode-enabled", null, isNullableBoolean);

  // See if user has set a browser or OS preference for dark mode.
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  // If no value is set in localStorage, fall back to the media query preference
  const darkMode = value ?? prefersDarkMode;

  const setDarkMode = React.useCallback(
    (newValue: React.SetStateAction<boolean>) => {
      if (typeof newValue === "boolean") {
        setValue(newValue);
      } else {
        setValue((previous) => {
          if (typeof previous === "boolean") return newValue(previous);

          return newValue(window.matchMedia("(prefers-color-scheme: dark)").matches);
        });
      }
    },
    [setValue],
  );

  return [darkMode, setDarkMode] as const;
}
