import styled from "styled-components";
import { ThemeProviderProps } from "@src/theme";

const Paragraph = styled.p<ThemeProviderProps>`
  color: ${(props) => props.theme.site.color.SubtleForeground};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.7rem;
  font-feature-settings: "kern", "liga", "clig", "calt";
  margin: 0 auto 1.4rem auto;
  font-family: ${(props) => props.theme.fonts.Body};
  width: ${(props) => props.theme.dimensions.contentWidth};
  max-width: 90%;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    font-size: 0.8125rem;
    line-height: 1.375rem;
  }
`;

export default Paragraph;
