import { MDXProvider } from "@mdx-js/react";
import Plausible from "plausible-tracker";
import React from "react";
import styled from "styled-components";
import MdxElements from "./components/elements/mdx";
import { Theme } from "./theme";

const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
`;

// eslint-disable-next-line import/prefer-default-export
export const App: React.FC<React.PropsWithChildren> = ({ children }) => {
  React.useEffect(() => {
    const { enableAutoPageviews } = Plausible({
      domain: "engineering.kablamo.com.au",
      // apiHost: "https://engineering.kablamo.com.au/pl",
    });

    enableAutoPageviews();
  }, []);

  return (
    <Container>
      <MDXProvider components={MdxElements}>
        <Theme showDarkModeToggle>{children}</Theme>
      </MDXProvider>
    </Container>
  );
};
