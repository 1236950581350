import styled from "styled-components";
import { ThemeProviderProps } from "@src/theme";

// Base styles for Headings

const Heading1 = styled.h1<ThemeProviderProps>`
  color: ${(props) =>
    (props.color && props.theme.site.color[props.color]) || props.theme.site.color.Foreground};
  max-width: ${(props) => props.theme.dimensions.maxWidth};
`;

const Heading2 = styled.h2<ThemeProviderProps>`
  color: ${(props) =>
    (props.color && props.theme.site.color[props.color]) || props.theme.site.color.Foreground};
  max-width: ${(props) => props.theme.dimensions.maxWidth};
  padding-bottom: 0.4rem;
  font-size: 2rem;
  margin: 1.8em auto 0.5em auto;
  font-family: ${(props) => props.theme.fonts.Title};
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    font-size: 1.125rem;
  }
`;
const Heading3 = styled.h3<ThemeProviderProps>`
  color: ${(props) =>
    (props.color && props.theme.site.color[props.color]) || props.theme.site.color.Foreground};
  max-width: ${(props) => props.theme.dimensions.maxWidth};
  padding-bottom: 0.4rem;
  margin: 1.8em auto 0.5em auto;
  font-family: ${(props) => props.theme.fonts.Title};
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    font-size: 0.9375rem;
  }
`;
const Heading4 = styled.h4<ThemeProviderProps>`
  color: ${(props) =>
    (props.color && props.theme.site.color[props.color]) || props.theme.site.color.Foreground};
  max-width: ${(props) => props.theme.dimensions.maxWidth};
  border-bottom: 1px solid ${(props) => props.theme.site.color.SubtleBackground};
  padding-bottom: 0.4rem;
  margin: 1.8em auto 0.5em auto;
`;

const Heading5 = styled.h5<ThemeProviderProps>`
  color: ${(props) =>
    (props.color && props.theme.site.color[props.color]) || props.theme.site.color.Foreground};
  max-width: ${(props) => props.theme.dimensions.maxWidth};
  border-bottom: 1px solid ${(props) => props.theme.site.color.SubtleBackground};
  padding-bottom: 0.4rem;
  margin: 1.8em auto 0.5em auto;
`;

const Heading6 = styled.h6<ThemeProviderProps>`
  color: ${(props) =>
    (props.color && props.theme.site.color[props.color]) ||
    props.theme.site.color.SubtleForeground};
  max-width: ${(props) => props.theme.dimensions.maxWidth};
  font-family: ${(props) => props.theme.fonts.Monospace};
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1rem;
  margin: 0.3rem auto;
  padding: 0;

  @media ${(props) => props.theme.mediaQueries.mobile} {
    font-size: 0.75rem;
  }
`;
export { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 };
