import React from "react";
import styled from "styled-components";
import { ThemedKablamoBolt } from "@src/components/kablamoBolt";
import { ThemeProviderProps } from "@src/theme";

const Outer = styled.div<ThemeProviderProps>`
  border-top: 1px solid ${(props) => props.theme.site.color.SubtleBackground};
  margin: 3rem auto 2rem auto;
  text-align: center;
  text-transform: uppercase;
  max-width: ${(props) => props.theme.dimensions.mainWidth};
`;

const Inner = styled.div<ThemeProviderProps>`
  background: ${(props) => props.theme.site.color.Background};
  padding: 0 1.6rem 0 1rem;
  color: ${(props) => props.theme.site.color.Foreground};

  margin: -6px auto 0 auto;
  width: 12px;
`;

const HR = ({ width }: { width?: React.CSSProperties["maxWidth"] }) => (
  <Outer style={{ maxWidth: width }}>
    <Inner>
      <ThemedKablamoBolt small subtle />
    </Inner>
  </Outer>
);

export default HR;
