import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import Switch from "react-switch";
import styled from "styled-components";
import KablamoLogoHorizontal from "@src/components/KablamoLogoHorizontal";
import { ThemedKablamoBolt } from "@src/components/kablamoBolt";
import MobileMenu from "@src/components/mobileMenu";
import { GetInTouchLink } from "@src/components/siteLinks";
import { ThemeProviderProps, ThemeConfig } from "@src/theme";

const Header = styled.div<ThemeProviderProps>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 1.2rem;
  align-items: center;
  width: ${(props) => props.theme.dimensions.maxWidth};
  padding-top: 1.5rem;
  padding-bottom: 0.8125rem;
  max-width: 98vw;
`;

const MenuLeft = styled.div`
  margin-left: 1.2rem;
`;

const MenuRight = styled.div`
  justify-self: end;
  margin-right: 1.2rem;

  *:not(:last-child) {
    margin-right: 1.7rem;
  }
`;

const DesktopOnly = styled.span<ThemeProviderProps>`
  @media ${(props) => props.theme.mediaQueries.mobile} {
    display: none;
  }
`;

const MobileOnly = styled.span<ThemeProviderProps>`
  @media ${(props) => props.theme.mediaQueries.desktop} {
    display: none;
  }
`;

const LogoLink = styled(OutboundLink)`
  display: inline-block;
  height: 16px;
  width: 86px;
`;

const DarkModeToggle = ({
  theme,
  darkMode: { isDarkMode, toggleHandler },
}: {
  theme: Omit<ThemeConfig, "color">;
  darkMode: {
    isDarkMode: boolean;
    toggleHandler: () => void;
  };
}) => {
  return (
    <Switch
      aria-checked={!isDarkMode}
      aria-label="Theme switch"
      onChange={toggleHandler}
      checked={!isDarkMode}
      uncheckedIcon={
        <span
          style={{
            display: "inline-block",
            marginLeft: "0.5em",
            marginTop: "0.05em",
            color: theme.site.color.SubtleForeground,
          }}
        >
          ☾
        </span>
      }
      checkedIcon={
        <span
          style={{
            display: "inline-block",
            marginLeft: "0.5em",
            marginTop: "0.1em",
            color: theme.site.color.SubtleForeground,
          }}
        >
          ☀
        </span>
      }
      onHandleColor={theme.siteInverted.color.Foreground}
      onColor={theme.siteInverted.color.SubtleForeground}
      offHandleColor={theme.site.color.Foreground}
      offColor={theme.siteInverted.color.SubtleForeground}
      width={60}
    />
  );
};

const NavHeader = ({
  theme,
  darkMode: { showToggle, isDarkMode, toggleHandler },
}: {
  theme: Omit<ThemeConfig, "color">;
  darkMode: { showToggle: boolean; isDarkMode: boolean; toggleHandler: () => void };
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const el = ref.current!;
    const observer = new ResizeObserver(() => {
      document.documentElement.style.setProperty("--nav-header-height", `${el.offsetHeight}px`);
    });
    observer.observe(el);
    return () => observer.disconnect();
  }, []);

  return (
    <Header ref={ref}>
      <MenuLeft>
        <DesktopOnly>
          <LogoLink href="https://www.kablamo.com.au/" aria-label="Kablamo website">
            <KablamoLogoHorizontal />
          </LogoLink>
        </DesktopOnly>
        <MobileOnly>
          <MobileMenu />
        </MobileOnly>
      </MenuLeft>
      <Link aria-label="Navigate to homepage" to="/" style={{ zIndex: 5 }}>
        <ThemedKablamoBolt />
      </Link>
      <MenuRight>
        <DesktopOnly>
          <GetInTouchLink />
        </DesktopOnly>
        {showToggle && (
          <DarkModeToggle
            theme={theme}
            darkMode={{
              isDarkMode,
              toggleHandler,
            }}
          />
        )}
      </MenuRight>
    </Header>
  );
};

export default NavHeader;
