import styled, { withTheme } from "styled-components";
import partnerBrandsDark from "@src/assets/images/partner_brands_dark.png";
import partnerBrandsLight from "@src/assets/images/partner_brands_light.png";
import Link from "@src/components/elements/link";
import { ThemeProviderProps, ThemeScheme, ThemeConfig } from "@src/theme";

const FooterWrapper = styled.div<ThemeProviderProps>`
  background-color: ${(props) => props.theme.site.color.Footer.Background};
  text-align: center;
  font-family: ${(props) => props.theme.fonts.HeaderSans};
  color: ${(props) => props.theme.site.color.Footer.Foreground};
  width: 100%;
  padding: 0 1.25rem;
`;

const FooterHeader = styled.h2`
  padding-top: 50px;
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
`;

const FooterLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const FooterLink = styled(Link)<ThemeProviderProps>`
  color: ${(props) => props.theme.site.color.Footer.Foreground};
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 1em;
`;

const KablamoLocation = styled.div`
  text-transform: uppercase;
  margin-top: 70px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

const Copyright = styled.div`
  font-size: 12px;
  line-height: 15px;
  margin-top: 30px;
  padding-bottom: 30px;
`;

const Footer = ({ theme }: { theme: ThemeConfig }) => (
  <FooterWrapper>
    <FooterHeader>Our Partners</FooterHeader>
    <img
      src={theme.scheme === ThemeScheme.LIGHT ? partnerBrandsDark : partnerBrandsLight}
      alt="Our partners"
    />
    <FooterLinksWrapper>
      <FooterLink to="https://github.com/KablamoOSS">Github</FooterLink>
      {/* <FooterLink */}
      {/*  to={"https://www.kablamo.com.au/jobs"} */}
      {/*  style={{ margin: "0 2rem" }} */}
      {/* > */}
      {/*  Working With Us */}
      {/* </FooterLink> */}

      <FooterLink to="https://www.kablamo.com.au/terms">Terms</FooterLink>
      <FooterLink to="https://www.kablamo.com.au/privacy">Privacy Policy</FooterLink>
    </FooterLinksWrapper>
    <KablamoLocation>Level 1, 20 York St, Sydney, NSW, 2000, Australia</KablamoLocation>
    <Copyright>
      Copyright © 2018-{new Date().getFullYear()}. All rights reserved. ABN: 36 618 932 737
    </Copyright>
  </FooterWrapper>
);

export default withTheme(Footer);
