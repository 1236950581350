import { compact, get } from "lodash";
import Highlight, { defaultProps, type Language as PrismLanguage } from "prism-react-renderer";
import * as React from "react";
import { withTheme } from "styled-components";
import { ThemeConfig } from "@src/theme";
import { prismTheme } from "@src/theme/prism";
import { Code } from "../code/mdx";
import {
  Filename,
  HighlightedLine,
  HighlightedLineNumber,
  Language,
  Line,
  LineNumber,
  Meta,
  Pre as StyledPre,
} from "./styled";

const LinesToHighlightExpression = /{([\d,-]+)}/;

const calculateLinesToHighlight = (meta: string) => {
  if (!LinesToHighlightExpression.test(meta)) {
    return () => false;
  }
  const lineNumbers = LinesToHighlightExpression.exec(meta)![1]
    .split(",")
    .map((v) => v.split("-").map((n) => parseInt(n, 10)));
  return (index: number) => {
    const lineNumber = index + 1;
    const inRange = lineNumbers.some(([start, end]) =>
      end ? lineNumber >= start && lineNumber <= end : lineNumber === start,
    );
    return inRange;
  };
};

const getKeyValuePairs = (meta: string) => {
  const KeyValueExpression = /(\w+)=([^\s]+)/g;
  const test = KeyValueExpression.exec(meta);
  if (typeof test === "undefined" || !Array.isArray(test)) {
    return {};
  }
  return compact(
    test.map((pair) => {
      if (pair.includes("=")) {
        const [k, v] = pair.split("=");
        return {
          [k]: v,
        };
      }
      return undefined;
    }),
  ).reduce((acc, item) => {
    return {
      ...acc,
      [item.k]: item.v,
    };
  });
};

const plainTextType = "plaintext";

// eslint-disable-next-line import/prefer-default-export
export const Pre = withTheme(
  ({
    children,
    theme,
    ...props
  }: Omit<React.ComponentPropsWithoutRef<"pre">, "color"> & {
    theme: ThemeConfig;
  }) => {
    const codeProps =
      React.isValidElement(children) && children.type === Code
        ? (children.props as React.ComponentPropsWithoutRef<"code">)
        : null;

    if (!codeProps || typeof codeProps.children !== "string") {
      return <StyledPre {...props}>{children}</StyledPre>;
    }

    const code = codeProps.children;
    const language = codeProps.className
      ? codeProps.className.replace(/language-/, "")
      : plainTextType;
    const meta: string = get(codeProps, ["meta"]) ?? "";
    const shouldHighlightLine = calculateLinesToHighlight(meta);
    const pairs = getKeyValuePairs(meta);

    const onCopy: React.ClipboardEventHandler<HTMLPreElement> = (e) => {
      // Firefox has an issue where additional erroneous newlines are added even though the
      // `<LineNumberComponent />` has `user-select: none`, so override the default behaviour
      // @see https://stackoverflow.com/a/67781496
      e.clipboardData.setData("text/plain", document.getSelection()!.toString());
      e.preventDefault();
    };

    return (
      <Highlight
        {...defaultProps}
        code={code}
        language={language as PrismLanguage}
        theme={prismTheme(theme)}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <StyledPre {...props} className={className} style={style} onCopy={onCopy}>
            <Meta>
              <Language>{language === plainTextType ? "" : language}</Language>
              {pairs && pairs.filename && <Filename>{pairs.filename}</Filename>}
            </Meta>
            {tokens.map((line, i) => {
              const lineProps = getLineProps({ line, key: i });
              let LineComponent = Line;
              let LineNumberComponent = LineNumber;
              if (shouldHighlightLine(i)) {
                LineComponent = HighlightedLine;
                LineNumberComponent = HighlightedLineNumber;
              }

              return language === plainTextType && line[0].empty ? null : (
                // eslint-disable-next-line react/no-array-index-key
                <LineComponent {...lineProps} key={i}>
                  <LineNumberComponent data-line-number={i + 1} />
                  {line.map((token, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span {...getTokenProps({ token, key })} key={key} />
                  ))}
                </LineComponent>
              );
            })}
          </StyledPre>
        )}
      </Highlight>
    );
  },
);
